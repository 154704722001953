.profile__grid__card {
  min-height: 200px;
}

.channel__card {
  min-height: 16rem;
}

.react-tabs__tab--selected {
  background: #3f83f8 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.react-tabs__tab {
  bottom: 0 !important;
  padding: 6px 20px !important;
}

.min-h-3 {
  min-height: 3rem;
}

.min-h-4 {
  min-height: 4rem;
}

.max-h-41rem {
  max-height: 41rem;
}
